import React, { useEffect, useState, useContext, useRef } from "react";
import Footer from "../../components/Footer";
import "./_Events.scss";
import { IconButton, Switch, FormControlLabel } from "@mui/material";
import Spinner from 'react-bootstrap/Spinner';
import EventContext from "../../components/EventContext";
import GlobalURLContext from "../../components/GlobalURLContext";
import InfoContext from "../../components/InfoContext";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ProgressiveImage from "../../components/ProgressiveImage";
import EventImage from "../../images/Desktop+Tablet/Section Graphics/desktop_Events.jpg";
import EventImageCompressed from "../../images/Desktop+Tablet/Section Graphics/desktop_Events_compressed.jpeg";
import EventImageMobile from "../../images/Mobile/Events.jpg";
import EventImageCompressedMobile from "../../images/Mobile/Events_compressed.jpeg";

import { styled } from '@mui/material/styles';
import { gridColumnsTotalWidthSelector } from "@mui/x-data-grid";

function Events({ setLoggedIn }) {
    const globalURLContext = useContext(GlobalURLContext);

    const eventContext = useContext(EventContext);
    const infoContext = useContext(InfoContext);

    const [events, setEvents] = useState(eventContext.events);
    const [eventsBackup, setEventsBackup] = useState(eventContext.events);
    const [isLoading, setIsLoading] = useState(false);
    const [eventsChecked, setEventsChecked] = useState(false);

    useEffect(() => {
        if (!eventsChecked && !events) {
            getEvents();
            setEventsChecked(true);
        }
    }, [events]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    function getEvents() {
        setIsLoading(true)
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 60000);
        let headers = new Headers();
        let token = window.sessionStorage.getItem("accessToken")
        headers.append("Authorization", `Bearer ${token}`)
        let options = {
            headers: headers,
            redirect: "follow",
            signal: controller.signal
        }
        let url = `${globalURLContext.globalURL}/registeredevents`;

        fetch(url, options
        ).then(response => {
            if (response.status === 401) {
                setLoggedIn(false);
                window.location.reload();
                window.sessionStorage.clear();
                throw new Error("Authentication Error")
            }
            if (!response.ok) throw new Error(`Unexpected response status ${response.status}`)
            else return response.json()
        }).then(result => {
            let events = result;
            events = events?.map((e, idx) => ({ ...e, id: e.EVENT_ID }))
            events.sort((e1, e2) => {
                if (new Date(e1.DATE) > new Date(e2.DATE)) return 1
                else if (new Date(e1.DATE) < new Date(e2.DATE)) return -1
                return 0
            })
            eventContext.setEvents(events);
            setEvents(events);
            setIsLoading(false);
        }).catch(e => {
            console.log(e);
            setIsLoading(false);
        })
    }

    function filterEvents(events) {
        return events?.filter(e => {
            if (!!e?.CONSTRAINTS) {
                for (let [k, v] of Object.entries(JSON.parse(e?.CONSTRAINTS))) {
                    if (typeof v === 'object' && !v?.includes(infoContext.info[k])) {
                        return false
                    }
                }
            }
            return true;
        })
    }

    function register(e) {
        e.preventDefault();
        //setIsLoadingRegister(true);

        let eventID = e.currentTarget.id;
        setEventsBackup(events);
        let updatedEvents = events?.map((ev, idx) => {
            if (ev.EVENT_ID === eventID) {
                return { ...ev, id: eventID, REGISTRATION_STATUS: "Registered" }
            }
            return ev
        })
        setEvents([...updatedEvents]);
        eventContext.setEvents([...updatedEvents]);

        let headers = new Headers();
        let token = window.sessionStorage.getItem("accessToken")
        headers.append("Authorization", `Bearer ${token}`);
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");
        let options = {
            headers: headers,
            redirect: "follow",
            method: "POST",
            body: JSON.stringify({ EVENT_ID: eventID, MEETING_ID: events.find(e => e.id === eventID)?.MEETING_ID, email: infoContext.info.email, given_name: infoContext.info.firstName, family_name: infoContext.info.lastName, })
        }
        let url = `${globalURLContext.globalURL}/register`;

        fetch(url, options
        ).then(response => {
            if (response.status === 401) {
                setLoggedIn(false);
                window.location.reload();
                window.sessionStorage.clear();
                throw new Error("Authentication Error")
            }
            if (!response.ok) throw new Error(`Unexpected response status ${response.status}`)
            else return response.json()
        }).then(result => {
            let updatedEvents = events?.map((ev, idx) => {
                if (ev.EVENT_ID === eventID) {
                    return { ...ev, id: eventID, REGISTRATION_STATUS: "Registered" }
                }
                return ev
            })
            setEvents([...updatedEvents]);
        }).catch(e => {
            console.log(e);
            setEvents([...eventsBackup]);
        })
    }


    function unregister(e) {
        e.preventDefault();

        let eventID = e.currentTarget.id;
        setEventsBackup(events);
        let updatedEvents = events?.map((ev, idx) => {
            if (ev.EVENT_ID === eventID) {
                return { ...ev, id: eventID, REGISTRATION_STATUS: "" }
            }
            return ev
        })
        setEvents([...updatedEvents]);
        eventContext.setEvents([...updatedEvents]);


        let headers = new Headers();
        let token = window.sessionStorage.getItem("accessToken")
        headers.append("Authorization", `Bearer ${token}`);
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");
        let options = {
            headers: headers,
            redirect: "follow",
            method: "POST",
            body: JSON.stringify({ EVENT_ID: eventID })
        }

        let url = `${globalURLContext.globalURL}/unregister`;

        fetch(url, options
        ).then(response => {
            if (response.status === 401) {
                setLoggedIn(false);
                window.location.reload();
                window.sessionStorage.clear();
                throw new Error("Authentication Error")
            }
            if (!response.ok) throw new Error(`Unexpected response status ${response.status}`)
            else return response.json()
        }).catch(e => {
            console.log(e);
            setEvents([...eventsBackup]);
        })
    }

    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        marginRight: 12,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === 'dark' ? 'rgb(247,135,15)' : 'rgb(247,135,15)',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    }));

    return (
        <div className="page">
            <div className="event-content">

                {window.innerWidth > 600 ?
                    <div style={{ padding: "24px 1% 0px 1%", minHeight: 120, marginBottom: 20, backgroundColor: "white" }}>
                        <div style={{ maxWidth: "1400px", margin: "auto" }}>
                            <ProgressiveImage
                                src={EventImage}
                                placeholderSrc={EventImageCompressed}
                                style={{
                                    maxWidth: "100%",
                                    minWidth: "100%",
                                    paddingBottom: "24px",
                                }}
                            />
                        </div>
                    </div>
                    :
                    <div style={{ padding: "24px 2% 0px 2%", minHeight: 120, marginBottom: 20, backgroundColor: "white" }}>
                        <div style={{ maxWidth: "1400px", margin: "auto" }}>
                            <ProgressiveImage
                                src={EventImageMobile}
                                placeholderSrc={EventImageCompressedMobile}
                                style={{
                                    maxWidth: "100%",
                                    minWidth: "100%",
                                    paddingBottom: "24px",
                                }}
                            />
                        </div>
                    </div>
                }
                <div style={{ paddingLeft: 0, paddingRight: 0, display: "flex", justifyContent: "center" }}>

                    <h2
                        style={{
                            width: "340px",
                            marginTop: 8,
                            padding: 6,
                            border: "2px solid rgb(247,135,15)",
                            color: "rgb(91,89,89)",
                            fontFamily: "Trim-SemiBold",
                            fontSize: "33px",
                            borderRadius: "15px",
                            display: "flex",
                            justifyContent: "center",
                            marginBottom: "18px",
                            backgroundColor: "white"
                        }}
                    >
                        UPCOMING EVENTS
                    </h2>
                </div>
                {isLoading ?
                    <div className={`event-spinner-container`} >
                        <Spinner className="event-spinner" style={{ width: 70, height: 70 }} animation="border" variant="warning" />
                    </div>
                    :
                    filterEvents(events)?.length ?
                        <div style={{ display: "flex", flexDirection: "column", gap: "18px" }}>
                            {filterEvents(events)?.map(ev => (
                                <div className="event-tile">
                                    <h5 style={{ fontFamily: "Trim-SemiBold", marginBottom: 24 }}>{ev.TITLE}</h5>
                                    <div className="event-description">{ev.DESCRIPTION}</div>

                                    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                                        <div className="event-access-info" >
                                            <div className="event-date"><CalendarMonthIcon /><span style={{ marginLeft: 8 }}>{new Date(ev.DATE).toUTCString().slice(0, -13)}</span></div>
                                            <div className="schedule"><AccessTimeIcon /><span style={{ marginLeft: 8 }}>{ev.START_TIME} - {ev.END_TIME}</span></div>
                                            <div><LocationOnIcon /><span style={{ marginLeft: 8 }}>{ev.LOCATION}</span></div>
                                        </div>
                                        <FormControlLabel sx={{ width: "120px", position: "relative", left: "12px", top: "20px" }} control={
                                            <IOSSwitch id={ev.EVENT_ID}
                                                color="warning"
                                                disabled={new Date(ev.REGISTRATION_DEADLINE) < new Date()}
                                                checked={ev.REGISTRATION_STATUS === "Registered"}
                                                onChange={(e) => ev.REGISTRATION_STATUS === "Registered" ? unregister(e) : register(e)} />}
                                            label={<span style={{ fontFamily: "Trim-Regular" }}>{ev.REGISTRATION_STATUS !== "Registered" ? (new Date(ev.REGISTRATION_DEADLINE) < new Date() ? <div style={{ fontSize: "12px", lineHeight: "14px" }}>Registrations are now closed</div> : "Register") : "Registered"}</span>}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div> :
                        <div style={{ margin: "auto", marginTop: 38, textAlign: "center", fontSize: "22px", fontFamily: "Trim-SemiBold", color: "rgb(91,89,89)" }}>There are no upcoming events... <br />Please check back later</div>
                }
            </div>
            <Footer />
        </div>)
}


export default Events;