import React, { useContext, useEffect, useState, useRef } from "react";
import Footer from "../../components/Footer";
import Menu from "../../components/Menu";
import "./_Jobs.scss";
import * as ReactDOM from "react-dom";
import GlobalURLContext from "../../components/GlobalURLContext";
import JobRecommendationsContext from "../../components/JobRecommendationsContext";
import OpenJobsContext from "../../components/OpenJobsContext";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
    Button,
    TextInput,
    MultiSelect,
    Dropdown,
    Modal,
    Tag,
} from "@carbon/react";
import Spinner from "react-bootstrap/Spinner";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import { validateInput, shuffleArray } from "../../Helpers";
import { ArrowUp, Favorite, Reset, Pin, PinFilled } from "@carbon/icons-react";
import { Checkbox, FormLabel } from "@mui/material";
import { registerClick } from "../../Helpers";
import JobModal from "../../components/JobModal";
import InfoContext from "../../components/InfoContext";
import ProgressiveImage from "../../components/ProgressiveImage";
import JobsImage from "../../images/Desktop+Tablet/Section Graphics/desktop_jobs.jpg";
import JobsImageCompressed from "../../images/Desktop+Tablet/Section Graphics/desktop_jobs_compressed.jpeg";
import JobsImageMobile from "../../images/Mobile/Open_Jobs.jpg";
import JobsImageCompressedMobile from "../../images/Mobile/Open_Jobs_compressed.jpeg";

const companies = [
    "Accenture",
    "AIG",
    "Amazon",
    "American Express",
    "Aon",
    "Bank of America",
    "BlackRock",
    "Blackstone",
    "Bloomberg",
    "BNP Paribas",
    "BNY Mellon",
    "Citi",
    "City of New York",
    "Con Edison",
    "Deloitte",
    "EY",
    "Goldman Sachs",
    "Google",
    "IBM",
    "JPMorgan Chase",
    "KPMG",
    "Mastercard",
    "McKinsey",
    "Memorial Sloan Kettering",
    "Microsoft",
    "Montefiore",
    "Morgan Stanley",
    "Mount Sinai",
    "New York Presbyterian",
    "Northwell Health",
    "PwC",
    "Ralph Lauren",
    "State of New York",
    "TD Bank",
    "The New York Times",
    "Tishman Speyer",
    "Travelers",
    "Verizon",
    "Wells Fargo",
].map((c, idx) => ({ id: idx, text: c, label: c }));

function Jobs({ setLoggedIn }) {
    const globalURLContext = useContext(GlobalURLContext);
    const jobRecommendationsContext = useContext(JobRecommendationsContext);
    const openJobsContext = useContext(OpenJobsContext);

    const [openJobs, setOpenJobs] = useState(openJobsContext.openJobs);

    const [isLoadingRecos, setIsLoadingRecos] = useState(false);
    const [isLoadingOpenJobs, setIsLoadingOpenJobs] = useState(false);
    //const [resumeUploaded, setResumeUploaded] = useState(false);

    const [filteringTitle, setFilteringTitle] = useState("");
    //const [filteringLocation, setFilteringLocation] = useState("");
    const [filteringCompanies, setFilteringCompanies] = useState([]);
    const [filteringJobType, setFilteringJobType] = useState("");

    const [invalidJobTitle, setInvalidJobTitle] = useState(false);

    const [noJobsMessageType, setNoJobsMessageType] = useState("open positions");

    const [showFavorites, setShowFavorites] = useState(false);
    const [favorites, setFavorites] = useState(new Set());

    const [open, setOpen] = useState(false);
    const [selectedJob, setSelectedJob] = useState();

    const [jobEmbeddings, setJobEmbeddings] = useState({});

    const recoModalLauncherRef = useRef();
    // Model loading
    const [ready, setReady] = useState(null);
    const [disabled, setDisabled] = useState(false);
    const [progressItems, setProgressItems] = useState([]);

    // Inputs and outputs
    const [input, setInput] = useState('I love walking my dog.');
    const [output, setOutput] = useState('');

    const infoContext = useContext(InfoContext);

    async function registerRClick(id, jobID) {
        return registerClick(id, jobID, setLoggedIn);
    }

    useEffect(() => {
        //console.log(JSON.stringify(jobRecommendationsContext.recos))
        //if (!jobRecommendationsContext.recos || jobRecommendationsContext.recos.length === 0) {
        //    getJobRecommendations();
        //}
        window.scrollTo(0, 0);
        if (
            !openJobsContext.openJobs /*|| openJobsContext.openJobs.length === 0*/
        ) {
            getOpenJobs(null, false);
        }
        /*document.getElementById("downshift-51-toggle-button").onclick = (e) => {
                    window.scrollTo(0,0);
            }*/
        let favs = new Set();
        openJobsContext.openJobs?.forEach((j) => {
            if (j.favorite) favs.add(j.ID);
        });
        setFavorites(favs);
    }, []);

    useEffect(() => {
        getOpenJobs(0, false);
    }, [showFavorites]);



    /*function getJobRecommendations() {
          setIsLoadingRecos(true);
          let token = window.sessionStorage.getItem("accessToken");
  
          let headers = new Headers();
          headers.append("Accept", "application/json");
          headers.append("Authorization", `Bearer ${token}`);
  
          let options = {
              method: "GET",
              redirect: "follow",
              headers: headers,
          };
  
          fetch(`${globalURLContext.globalURL}/recommendations`, options)
              .then((response) => response.json())
              .then((result) => {
                  //console.log(result);
                  jobRecommendationsContext.setRecos(result);
                  setIsLoadingRecos(false);
              })
              .catch((e) => {
                  console.log(e);
                  setIsLoadingRecos(false);
              });
      }*/

    async function getMatches(embeddedTitle, oJobs) {
        //cos for vectors?
        let matches = oJobs.filter(j => Math.cos(jobEmbeddings[j.JOB_ID], embeddedTitle) > 0.6);
        return matches;
    }

    async function getOpenJobs(offset, unfiltered) {

        if (offset === 0) {
            let oJobs = openJobsContext.openJobs;

            if (showFavorites) {
                oJobs = oJobs.filter((j) => j.favorite);
            }
            if (unfiltered) {
                setOpenJobs(oJobs);
                return;
            }
            if (!validateInput(filteringTitle, "job_title")) {
                setInvalidJobTitle(true);
                return;
            }

            oJobs = oJobs?.filter(
                (j) =>
                    filteringTitle
                        ?.trim()
                        ?.toLowerCase()
                        ?.split(" ")
                        .some((w) => j.POSITION?.toLowerCase().includes(w)) &&
                    (filteringCompanies?.length === 0 ||
                        filteringCompanies
                            ?.map((c) => c.text.toLowerCase())
                            ?.includes(j?.COMPANY?.toLowerCase()))
            );
            oJobs = oJobs?.filter(
                (j) => !filteringJobType || j.TYPE === filteringJobType
            )
            //let embeddedTitle = await embed(filteringTitle);
            //alert(JSON.stringify(embeddedTitle))
            //oJobs = await getMatches(embeddedTitle, oJobs);
            setOpenJobs(oJobs);
            //openJobsContext.setOpenJobs(oJobs);
            setNoJobsMessageType(
                !filteringJobType ? "jobs" : filteringJobType.toLowerCase() + "s"
            );
            return;
        }

        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 60000);
        setIsLoadingOpenJobs(true);
        let token = window.sessionStorage.getItem("accessToken");

        let headers = new Headers();
        headers.append("Accept", "application/json");
        headers.append("Authorization", `Bearer ${token}`);

        let options = {
            method: "GET",
            redirect: "follow",
            headers: headers,
            signal: controller.signal,
        };
        let url = new URL(`${globalURLContext.globalURL}/openjobs`);
        //url.searchParams.set("role", filteringTitle)
        //url.searchParams.set("city", filteringLocation)
        if (offset) url.searchParams.set("offset", offset);
        //url.searchParams.set("companies",filteringCompanies)

        fetch(url, options)
            .then((response) => {
                if (response.status === 401) {
                    setLoggedIn(false);
                    window.location.reload();
                    window.sessionStorage.clear();
                    throw new Error("Authentication Error");
                }
                return response.json();
            })
            .then((result) => {
                setIsLoadingOpenJobs(false);
                let newJobs = result
                    .filter(
                        (r) =>
                            (new Date(infoContext.info?.lastLogin) < new Date(r.ADDED) &&
                                (new Date() - new Date(r.ADDED)) / (1000 * 60 * 60 * 24) < 7) ||
                            (new Date() - new Date(r.ADDED)) / (1000 * 60 * 60 * 24) < 4
                    )
                    ?.map((r) => ({ ...r, new: true }));
                let expiringSoon = result
                    .filter(
                        (r) =>
                            !((new Date(infoContext.info?.lastLogin) < new Date(r.ADDED) &&
                                (new Date() - new Date(r.ADDED)) / (1000 * 60 * 60 * 24) < 7) ||
                                (new Date() - new Date(r.ADDED)) / (1000 * 60 * 60 * 24) < 4) &&
                            new Date(r.APPLICATION_DEADLINE) - new Date() < 7
                    )
                    ?.map((r) => ({ ...r, expiring: true }));

                let rest = result
                    .filter(
                        (r) =>
                            !((new Date(infoContext.info?.lastLogin) < new Date(r.ADDED) &&
                                (new Date() - new Date(r.ADDED)) / (1000 * 60 * 60 * 24) < 7) ||
                                (new Date() - new Date(r.ADDED)) / (1000 * 60 * 60 * 24) < 4) &&
                            new Date(r.APPLICATION_DEADLINE) - new Date() >= 7
                    )
                console.log("new " + JSON.stringify(newJobs))
                let jobs = shuffleArray(newJobs)?.concat(shuffleArray(expiringSoon.concat(rest)));
                setOpenJobs(jobs);
                openJobsContext.setOpenJobs(jobs);
                let favs = new Set();
                openJobsContext.openJobs?.forEach((j) => {
                    if (j.favorite) favs.add(j.ID);
                });
            })
            .catch((e) => console.log(e))
            .finally(() => {
                //setFilteringTitle("");
                //setFilteringLocation("");
                //setFilteringCompanies([]);
            });
    }

    function handleRecoTileClick(e, setOpen) {
        /*
            if (e.target.id?.startsWith("fav-")) {
                //handleFavorite(parseInt(e.target.id?.slice(4)))
                return;
            }*/

        /*if (
                !recoModalLauncherRef.current ||
                recoModalLauncherRef.current?.className === e.target?.className
            ) {
                setOpen(false);
            } else {
                setOpen(true);
            }*/
        setOpen((open) => !open);
    }

    /*
        function uploadResume() {
            setIsLoadingRecos(true);
            setResumeUploaded(true);
            const form = document.getElementById("resume-upload-jobs");
    
            let token = window.sessionStorage.getItem("accessToken");
    
            let headers = new Headers();
            headers.append("Authorization", `Bearer ${token}`)
    
            const formData = new FormData(form);
            fetch(`${globalURLContext.globalURL}/resume`, {
                method: "POST", 
                headers: headers,
                redirect: "follow",
                body:formData
            })
            .then(response => response.json())
            .then(result => {
                jobRecommendationsContext.setRecos(result);
                setIsLoadingRecos(false);
                setResumeUploaded(false);
            })
        }
        */

    useEffect(() => {
        let updatedJobs =
            openJobsContext.openJobs?.map((j) => {
                return favorites.has(j.ID)
                    ? { ...j, favorite: true }
                    : { ...j, favorite: false };
            }) ?? [];
        let updatedFilteredJobs =
            openJobs?.map((j) => {
                return favorites.has(j.ID)
                    ? { ...j, favorite: true }
                    : { ...j, favorite: false };
            }) ?? [];
        setOpenJobs(updatedFilteredJobs);
        openJobsContext.setOpenJobs(updatedJobs);
    }, [favorites]);

    function handleFavorite(e, id) {
        let favs = favorites;
        let action;
        if (favorites.has(id)) {
            favs.delete(id);
            action = "delete";
        } else {
            favs.add(id);
            action = "add";
        }
        setFavorites(new Set(favs));
        //setOpenJobs(openJobs?.map(j => j.ID === id ? { ...j, favorite: !j.favorite } : j) ?? [])
        // TODO force rerender after updating favorites, before making fetch call; useOptimistic?
        let token = window.sessionStorage.getItem("accessToken");

        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${token}`);

        let options = {
            headers: headers,
            method: "POST",
            redirect: "follow",
            body: JSON.stringify({ jobID: id, action: action }),
        };
        fetch(`${globalURLContext.globalURL}/favorite`, options)
            .then((response) => {
                if (response.status === 401) {
                    setLoggedIn(false);
                    window.location.reload();
                    window.sessionStorage.clear();
                    throw new Error("Authentication Error");
                }
                return response.json();
            })
            .catch((e) => console.log(e));
    }

    return (
        <div className="page">
            {typeof document === "undefined"
                ? null
                : ReactDOM.createPortal(
                    <JobModal
                        open={open}
                        setOpen={setOpen}
                        job={selectedJob}
                        setLoggedIn={setLoggedIn}
                    />,
                    document.body
                )}
            <div className="jobs-content" style={{ position: "relative" }}>
                {window.innerWidth > 600 ?
                    <div style={{ padding: "24px 1% 0px 1%", minHeight: 120, marginBottom: 20, backgroundColor: "white" }}>
                        <div style={{ maxWidth: "1400px", margin: "auto" }}>
                            <ProgressiveImage
                                src={JobsImage}
                                placeholderSrc={JobsImageCompressed}
                                style={{
                                    maxWidth: "100%",
                                    minWidth: "100%",
                                    paddingBottom: "24px",
                                }}
                            />
                        </div>
                    </div>
                    :
                    <div style={{ padding: "24px 2% 0px 2%", minHeight: 120, marginBottom: 8, backgroundColor: "white" }}>
                        <div style={{ maxWidth: "1400px", margin: "auto" }}>
                            <ProgressiveImage
                                src={JobsImageMobile}
                                placeholderSrc={JobsImageCompressedMobile}
                                style={{
                                    maxWidth: "100%",
                                    minWidth: "100%",
                                    paddingBottom: "24px",
                                }}
                            />
                        </div>
                    </div>}
                {/*<h3 className="page-title job-title">Job Recommendations</h3>
                {isLoadingRecos ? <>
                   {resumeUploaded && <div style={{marginBottom:48, marginTop:0, fontFamily:"Trim-SemiBold",fontSize:20, color:"rgb(91,89,89)"}}>Thank you for uploading your resume. <br />Your job recommendations are being generated.  <br />This might take a few seconds...</div>}
                    <div className={`jobs-spinner-container ${resumeUploaded ? "spinner-resume": ""}`} >
                        <Spinner className="spinner" style={{width:70,  height:70}} animation="border" variant="warning" /> 
                    </div> 
                    </>
                    :
                    <>
                     {/*<div style={{fontFamily:"Trim-Regular", fontSize:"20px", fontWeight:800}}>Coming soon...</div>*}

               <div className="recos-blur" style={{display:"flex", position:"relative", justifyContent:"space-evenly"}}>
                <div className="gradient-left"></div>
                <div className="recos">
                   {/*<pre> {JSON.stringify(jobRecommendationsContext.recos, null, 2)}</pre>*}
                   {jobRecommendationsContext.recos.length > 0 ? 
                    jobRecommendationsContext.recos?.map((r, idx) => (
                        <ModalStateManager renderLauncher={({ setOpen }) => (
                        <div key={idx} className="reco" onClick={e => handleRecoTileClick(e, setOpen)}>
                            <div style={{display:"flex", height:32, justifyContent:"space-between"}}>
                                <h5 style={{color:"rgb(247,135,15)", fontFamily:"Trim-SemiBold"}}>{r.COMPANY}</h5>
                                <div style={{fontSize:13,marginTop:4, color:"rgb(91,89,89)"}}>{/*Published: *}{r.DATEOPEN?.slice(0,10)}</div>
                            </div>
                            <h4 style={{padding:"32px 0px", fontFamily:"Trim-SemiBold", color:"rgb(91,89,89)"}}>{r.ROLE}</h4>
                            <div style={{height:140, overflow:"ellipsis"}} dangerouslySetInnerHTML={{__html: r.DESCRIPTION.split(/[!.]|<br \/>/)?.slice(0,1).join(" ")}}>{/*r.description*}</div>
                            <div style={{justifyContent:"end", alignContent:"end"}}>
                            <a /*href="https://workday.com"* href={"https://"+r.LINK} target="_blank" rel="noreferrer">
                                <Button /*href={"https://"+r.LINK}* ref={recoModalLauncherRef} className="apply-btn" renderIcon={ArrowForwardIcon}>Apply{/*r.ATS*}</Button>
                            </a>
                            </div>
                        </div>)}>
                        {({ open, setOpen }) => (
                            <Modal
                                //size="sm"
                                modalHeading={r.COMPANY}
                                primaryButtonText={`Apply on ${r.ATS}`}
                                //secondaryButtonText="Cancel"
                                hasScrollingContent
                                onRequestSubmit={() => {window.open("https://"+r.LINK, "_blank");setOpen(false)}}
                                onRequestClose={() => setOpen(false)}
                                open={open}
                            >
                                <h4 style={{color:"rgb(91,89,89)", marginBottom:"21px", fontFamily:"Trim-Bold"}}>{r.ROLE}</h4>
                               <div dangerouslySetInnerHTML={{__html: r.DESCRIPTION}}></div>
                            </Modal>
                        )}
                        </ModalStateManager>
                    ))
                :
                (<div className="resume-upload-container" style={{display:"block"}}>
                    <div style={{marginBottom:24, marginTop:48, fontFamily:"Trim-SemiBold",fontSize:20, color:"rgb(91,89,89)"}}>You need to upload your resume in order to see relevant job recommendations</div>
                <div>
                    {/*<div className="cds--label">Upload Resume</div>*}
                    <form style={{marginTop:0, maxWidth:200, margin:"auto"}} /*action={`${globalURLContext.globalURL}/resume`}* method="post" id="resume-upload-jobs" target="_blank" encType="multipart/form-data">
                        <input style={{fontSize:14, backgroundColor:"white", padding:"6px 8px"}} onChange={() => uploadResume()} type="file" name="resume" />
                        {/*<input style={{fontSize:14, backgroundColor:"white", padding:"8px 12px"}} type="submit"></input>*}
                    </form>
                </div>                   
            </div>)
           
                }
                    </div>
                    <div className="gradient-right"></div>
                    </div>
            </>*/}
                {/*TODO add sroll listener*/}
                {parseInt(window.scrollY) > 4000 && (
                    <div
                        style={{
                            zIndex: 1000,
                            borderRadius: "50%",
                            position: "fixed",
                            bottom: 105,
                            right: 32,
                        }}
                    >
                        <IconButton
                            style={{
                                color: "black",
                                width: 57,
                                height: 57,
                                borderRadius: "50%",
                            }}
                            renderIcon={ArrowUp}
                        ></IconButton>
                    </div>
                )}
                <div className="all-jobs">
                    {/*<div
                        style={{
                            paddingLeft: 24,
                            paddingRight: 24,
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <h2
                            style={{
                                width: "250px",
                                marginTop: 4,//32,
                                padding: 6,
                                border: "2px solid rgb(247,135,15)",
                                color: "rgb(91,89,89)",
                                //color: "white",
                                fontFamily: "Trim-SemiBold",
                                fontSize: "36px",
                                borderRadius: "15px",
                                backgroundColor: "white"
                                //backgroundColor: "rgb(247,135,15)",
                            }}
                        >
                            OPEN JOBS
                        </h2>
                    </div>*/}
                    <>
                        <div className="job-filtering">
                            <div className="job-filters">
                                <TextInput
                                    className="job-filtering-input ti-jobs"
                                    size="lg"
                                    light
                                    invalid={invalidJobTitle}
                                    labelText="Job Title"
                                    placeholder="Enter role or field"
                                    value={filteringTitle}
                                    onChange={(e) => {
                                        setFilteringTitle(e.target.value);
                                        setInvalidJobTitle(false);
                                    }}
                                ></TextInput>
                                <Dropdown
                                    className="job-filtering-input"
                                    size="lg"
                                    items={["Internship", "Entry-level position"]}
                                    light
                                    selectedItem={filteringJobType}
                                    label="Select job type"
                                    titleText="Job Type"
                                    onChange={({ selectedItem }) =>
                                        setFilteringJobType(selectedItem)
                                    }
                                ></Dropdown>
                                <MultiSelect
                                    className="job-filtering-input"
                                    size="lg"
                                    light
                                    titleText="Companies"
                                    items={companies}
                                    label="Select companies"
                                    selectedItems={filteringCompanies}
                                    onChange={({ selectedItems }) =>
                                        setFilteringCompanies(selectedItems)
                                    }
                                ></MultiSelect>
                                <div
                                    style={{
                                        display: "flex",
                                        marginTop: "8px",
                                        maxHeight: "69px",
                                        justifyContent: "space-between",
                                        alignItems: "end",
                                    }}
                                >
                                    <button
                                        className="job-search-submit"
                                        onClick={() => getOpenJobs(0, false)}
                                    >
                                        Search{" "}
                                        <ArrowForwardIcon
                                            style={{ position: "relative", top: "0px" }}
                                        />
                                    </button>
                                </div>
                            </div>
                            <div
                                style={{
                                    marginBottom: 20,
                                    display: "flex",
                                    marginLeft: "7.5%",
                                    alignItems: "flex-start",
                                    verticalAlign: "middle",
                                }}
                            >
                                <div style={{ minWidth: "100px" }}>
                                    {openJobs?.length} result{openJobs?.length > 1 ? "s" : ""}
                                </div>
                                <div>
                                    <Tag
                                        className="job-search-clear-filters-tag"
                                        onClick={() => {
                                            setFilteringTitle("");
                                            setFilteringCompanies([]);
                                            setShowFavorites(false);
                                            setFilteringJobType("");
                                            setInvalidJobTitle(false);
                                            getOpenJobs(0, true);
                                        }}
                                    >
                                        Reset{" "}
                                        <Reset
                                            style={{
                                                verticalAlign: "middle",
                                                marginTop: 0,
                                            }} /*color="rgb(247,135,15)"*/
                                        />
                                    </Tag>
                                </div>
                                <div style={{ marginLeft: 22, position: "relative", top: -2 }}>
                                    <Checkbox
                                        style={{ padding: 0, marginRight: 3 }}
                                        sx={{
                                            color: "rgb(91,81,81)",
                                            "&.Mui-checked": {
                                                color: "rgb(91,81,81)",
                                            },
                                        }}
                                        id="fav-cb"
                                        checked={showFavorites}
                                        onChange={(e) => {
                                            setShowFavorites(!showFavorites);
                                        }}
                                    ></Checkbox>
                                    <span
                                        style={{ fontSize: 14, position: "relative", top: 1.5 }}
                                    >
                                        Pinned
                                    </span>
                                </div>
                            </div>
                        </div>
                        {isLoadingOpenJobs ? (
                            <div
                                className={`jobs-spinner-container`}
                                style={{ marginBottom: 250 }}
                            >
                                <Spinner
                                    className="spinner"
                                    style={{ width: 70, height: 70 }}
                                    animation="border"
                                    variant="warning"
                                />
                            </div>
                        ) : openJobs?.length ? (
                            <div className="open-jobs">
                                {openJobs.map((r, idx) => (
                                    <>
                                        <div
                                            id="job-tile"
                                            key={idx}
                                            className="job"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleRecoTileClick(e, setOpen);
                                                setSelectedJob(r);
                                                registerRClick("job-tile", r.ID);
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    height: 32,
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <h5
                                                    style={{
                                                        color: "rgb(247,135,15)",
                                                        fontFamily: "Trim-SemiBold",
                                                    }}
                                                >
                                                    {r?.COMPANY}
                                                </h5>
                                                <div style={{ display: "flex" }}>
                                                    {(r.expiring || r.new) && (
                                                        <div
                                                            style={{
                                                                fontSize: "11px",
                                                                color: "rgb(248,135,15)",
                                                                position: "relative",
                                                                top: -6,
                                                                border: "1px solid rgb(245,135,15)",
                                                                borderRadius: "25px",
                                                                fontFamily: "Trim-SemiBold",
                                                                padding: "2px 8px",
                                                                alignContent: "center",
                                                                alignSelf: "start",
                                                                backgroundColor: "white",
                                                            }}
                                                        >
                                                            {r.expiring ? "Few days left to apply" : "NEW"}
                                                        </div>
                                                    )}
                                                    <IconButton
                                                        disableRipple
                                                        sx={{ position: "relative", top: -10, right: -10 }}
                                                        id={`fav-${r.ID}`}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleFavorite(e, r.ID);
                                                            registerRClick(
                                                                favorites.has(r.ID) || r.favorite
                                                                    ? "favorite-job-unlike"
                                                                    : "favorite-job-like",
                                                                r.ID
                                                            );
                                                        }}
                                                        children={
                                                            favorites.has(r.ID) || r.favorite ? (
                                                                <PinFilled
                                                                    size="sm"
                                                                    width={"24px"}
                                                                    fill="rgb(245,135,15)"
                                                                />
                                                            ) : (
                                                                <Pin size="sm" width={"24px"} />
                                                            )
                                                        }
                                                    ></IconButton>
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    width: "100%",
                                                    maxHeight: "155px",
                                                    overflow: "hidden",
                                                }}
                                            >
                                                <h5
                                                    aria-label={r?.POSITION}
                                                    style={{
                                                        padding: "14px 0px 0px 0px",
                                                        textOverflow: "ellipsis",
                                                        fontFamily: "Trim-SemiBold",
                                                        color: "rgb(91,89,89)",
                                                    }}
                                                >
                                                    {
                            /*r.ROLE*/ r?.POSITION?.split(" ")?.length > 20
                                                            ? r?.POSITION?.split(" ")
                                                                ?.slice(0, 20)
                                                                ?.join(" ") + "..."
                                                            : r?.POSITION
                                                    }
                                                </h5>
                                            </div>
                                            <a
                                                href={r?.LINK}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <button
                                                    ref={
                                                        recoModalLauncherRef
                                                    }
                                                    size="md"
                                                    className="apply-btn"
                                                    style={{
                                                        position: "absolute",
                                                        bottom: 32,
                                                        right: 38,
                                                        height: "48px",
                                                    }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        registerRClick("apply-job-tile", r?.ID);
                                                    }}
                                                >
                                                    Apply
                                                    <ArrowForwardIcon
                                                        style={{
                                                            marginLeft: 8, //20,
                                                            position: "relative",
                                                            top: "0px",
                                                        }}
                                                    />
                                                </button>
                                            </a>
                                        </div>
                                    </>
                                ))}
                            </div>
                        ) : (
                            <div
                                style={{
                                    marginTop: 48,
                                    color: "rgb(91,89,89)",
                                    fontSize: "20px",
                                    lineHeight: "35px",
                                    paddingLeft: 48,
                                    paddingRight: 48,
                                    fontFamily: "Trim-SemiBold",
                                }}
                            >
                                Thank you for your interest in {noJobsMessageType}.<br />
                                Unfortunately, at the moment there are no {
                                    noJobsMessageType
                                }{" "}
                                matching you criteria.{" "}
                                <br />
                                We encourage you to check back regularly as new opportunities
                                are added frequently!
                            </div>
                        )}
                    </>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Jobs;
